import React, { Component } from "react";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	Container,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";

import { Link } from "gatsby";

import styles from "../styles/header.module.css";

export default class Header extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}
	render() {
		return (
			<Container fluid className={styles.header}>
				<div className={styles.headerContainer}>
					<Navbar dark expand="lg" className="px-0">
						<NavbarToggler onClick={this.toggle} />
						<p className={styles.logoText}>
							<Link to="/" style={{ color: "white" }}>
								KCK Beauty and Barber Academy
							</Link>
						</p>
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav
								navbar
								className="ml-0 w-100 justify-content-between"
							>
								<NavItem className="mb-0">
									<Link
										to="/"
										className={styles.headerNavLink}
									>
										Home
									</Link>
								</NavItem>
								<NavItem className="mb-0">
									<Link
										to="/about"
										className={styles.headerNavLink}
									>
										About Us
									</Link>
								</NavItem>
								<UncontrolledDropdown
									className="mb-0"
									nav
									inNavbar
								>
									<DropdownToggle
										nav
										caret
										className={styles.headerNavLink}
									>
										Students
									</DropdownToggle>
									<DropdownMenu right>
										<Link
											to="/admissions"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Admission
											</DropdownItem>
										</Link>

										<Link
											to="/catalog"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												School Catalog
											</DropdownItem>
										</Link>

										<Link
											to="/consumer-disclosures"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Consumer Disclosures
											</DropdownItem>
										</Link>

										<a
											href="/net-price-calculator"
											target="_blank"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Net Price Calculator
											</DropdownItem>
										</a>
									</DropdownMenu>
								</UncontrolledDropdown>
								<NavItem className="mb-0">
									<Link
										to="/financial-aid"
										className={styles.headerNavLink}
									>
										Financial Aid
									</Link>
								</NavItem>
								<UncontrolledDropdown
									className="mb-0"
									nav
									inNavbar
								>
									<DropdownToggle
										nav
										caret
										className={styles.headerNavLink}
									>
										Programs
									</DropdownToggle>
									<DropdownMenu right>
										<Link
											to="/barber-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Barber Program
											</DropdownItem>
										</Link>

										<Link
											to="/restricted-barber-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Restricted Barber Program
											</DropdownItem>
										</Link>

										<Link
											to="/cosmetology-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Cosmetology Program
											</DropdownItem>
										</Link>

										<Link
											to="/manicure-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Manicure Program
											</DropdownItem>
										</Link>

										<Link
											to="/skin-care-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Skin Care Specialist Program
											</DropdownItem>
										</Link>

										<Link
											to="/full-specialist-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Full Specialist Program
											</DropdownItem>
										</Link>

										<Link
											to="/instructor-program"
											className={styles.dropdownLinks}
										>
											<DropdownItem
												className={
													styles.dropdownLinksBtn
												}
											>
												Instructor Program
											</DropdownItem>
										</Link>
									</DropdownMenu>
								</UncontrolledDropdown>
								<NavItem className="mb-0">
									<Link
										to="/services"
										className={styles.headerNavLink}
									>
										Services
									</Link>
								</NavItem>
								<NavItem className="mb-0">
									<Link
										to="/va-benefits"
										className={styles.headerNavLink}
									>
										VA Benefits
									</Link>
								</NavItem>
								<NavItem className="mb-0">
									<Link
										to="/contact"
										className={styles.headerNavLink}
									>
										Contact Us
									</Link>
								</NavItem>
							</Nav>
						</Collapse>
					</Navbar>
				</div>
			</Container>
		);
	}
}
